<template>
  <div id="topidtime">
    <div v-if="ingstartnew==0">
      <img src="https://maiyuan-zhusun.oss-cn-hangzhou.aliyuncs.com/upload/feihuodong.jpg"/>
    </div>
    <div v-else-if="ingstartnew==1" class="syj_realttime">
      <!-- logo 个人中心 -->
      <toplogoCustomer-info></toplogoCustomer-info>
      <div class="syj_realttime-content">
        <div class="syj_pagetime">
          <countDown v-if="countdowndata" :countdowndata="countdowndata"/>
        </div>
      </div>
      <!-- 客户留言弹幕  start -->
      <message-info></message-info>
      <div class="sideicon">
        <!-- 游戏规则 -->
        <div class="popiconguestule" @click="isopenguestule">
          <img src="../../assets/images/guessruleicon.png"/>
        </div>
        <!-- 趋势对比图-->
        <div class="isonchart" @click="ishomechart">
          <img src="../../assets/images/charticon.png"/>
        </div>
        <!-- 排行榜-->
        <div class="tophome isonchart" @click="istop()">
          <img src="../../assets/images/hometop.png"/>
        </div>
      </div>
      <div class="syj-realtime-bottombg">
        
        <!-- 历届奖励 start -->
        <homehistoryWin-info :scrolllunbotime="scrolllunbotime"></homehistoryWin-info>
        
        <!--  奖金池 start-->
        <timeprizepool-info></timeprizepool-info>
        
        <guestrule-info v-if="onOpenguestule" @isGuessClose="isguessclose"></guestrule-info>
        <homecomparisonchart-info v-if="onishomechart" @ishomeChartclose="ishomechartclose"></homecomparisonchart-info>
        <!-- 实时订单-->
        <realTtimeorder-info v-if="startime && endtime" :starttime="startime" :endtime="endtime"
                             :timeorderNum="timeorderNum"></realTtimeorder-info>
      </div>
    </div>
  
  </div>
</template>
<script>
import countDown from "@/components/realTtimeorder/countDown";
import message from "@/components/message";
import guestrule from "@/components/guestRule";
import homehistoryWin from "@/components/homeHistoryWin";
import timeprizepool from "@/components/realTtimeorder/timePrizePool";
import homecomparisonchart from "@/components/homeComparisonchart";
import realtimeorder from "@/components/realTtimeorder/realTimeorder";
import Toplogocustomer from "@/components/toplogoCustomer";
import {currentActivity} from "../../units/api.js";

export default {
  name: "timeorderMian",
  data() {
    return {
      countdowndata: null,
      onOpenguestule: false,
      onishomechart: false,
      startime: null,
      endtime: null,
      ingstartnew: null,
      timeorderNum: null,
      scrolllunbotime: true,
      // timeEnd: " ",
    };
  },
  components: {
    "toplogoCustomer-info": Toplogocustomer,
    "message-info": message,
    "homehistoryWin-info": homehistoryWin,
    "homecomparisonchart-info": homecomparisonchart,
    "timeprizepool-info": timeprizepool,
    "realTtimeorder-info": realtimeorder,
    "guestrule-info": guestrule,
    countDown,
  },
  
  
  created: async function () {
    await this.jennycurrent().then((res) => {
      this.ingstartnew = res.data.data.ing;
    }).catch(() => {
      this.ingstartnew = 0;
    });
  },
  mounted() {
    this.jennycurrentActivity()
  },
  methods: {
    istop() {
      document.getElementById("topidtime").scrollIntoView({
        behavior: "smooth", // 平滑过渡
        block: "start", // 上边框与视窗顶部平齐。默认值
      });
    },
    jennycurrent() {
      return new Promise((resolve, reject) => {
        currentActivity()
            .then((res) => {
              resolve(res);
            })
            .catch((err) => {
              reject(err);
            });
      });
    },
    jennycurrentActivity() {
      let that = this;
      currentActivity().then((res) => {
        let response = res.data.data;
        that.countdowndata = response
        that.startime = response.activity_round.guess_time_start; // 开始时间
        console.log('that.startime', that.startime)
        that.endtime = response.activity_round.guess_time_end; // 结束时间
        that.ingstart = res.data.data.ing
        that.timeorderNum = response.activity_round.result_order_count
        console.log("dddd", that.result_order_count);
        console.log("sunyanjie122222222222222", that.timeorderNum);
      });
    },
    isopenguestule() {
      this.onOpenguestule = true
    },
    isguessclose() {
      this.onOpenguestule = false
    },
    ishomechart() {
      this.onishomechart = true
    },
    ishomechartclose() {
      this.onishomechart = false
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
.syj_realttime {
  .sideicon {
    width: 268px;
    height: 578px;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 10;
    
    .isonchart.tophome {
      top: 412px;
      
    }
  }
  
  .marquee {
    margin-top: -300px;
    z-index: 10;
  }
  
  ul {
    padding: 0;
    margin: 0;
    
    &.syj-content {
      padding: 16px 0;
    }
  }
  
  .syj_homeComparisonchart.syj_page-width {
    margin-bottom: 60px;
    padding-top: 40px;
    margin-top: 40px;
    z-index: 0;
  }
  
  .syj_realttime-content {
    background: url("https://maiyuan-zhusun.oss-cn-hangzhou.aliyuncs.com/upload/timeorderMian-banner.jpg") no-repeat;
    height: 350px;
    background-position: top center;
    background-size: inherit;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: -1;
  }
}

.syj-realtime-bottombg {
  padding-bottom: 30px;
  padding-top: 5px;
  display: inline-block;
  background: url(https://maiyuan-zhusun.oss-cn-hangzhou.aliyuncs.com/upload/homebg.jpg) repeat-y top center / contain  #2a0337;
  width: 100%;
}

.feihome {
  display: none;
}

.syj_page-width {
  max-width: 1200px;
  position: relative;
  margin: auto;
}
</style>
