<template>
  <div class="itemend">
    {{ sendTime }}
    <div class="itemss">
      <span v-for="h in hour" :key="h.key">
        {{ h }}
        </span
      ><i>:</i>
       <span v-for="min in minute" :key="min.key">
        {{ min }} 
        </span
      >
      <i>:</i>
      <span class="two" v-for="sec in second" :key="sec.key">{{ sec }}</span>
    </div>
  </div>
</template>
<style lang="less">
.syj_pagetime { 
  position: absolute;
    bottom: 24%;
    left: 49%;
    transform: translate(-50%, -50%);
    max-width: 500px;
    height: 62px;
}
.itemend {
  .itemss {
    i {
      font-size: 51px;
      font-style: inherit;
      font-family: auto;
      line-height: 48px;
      color: #fff;
      display: inline-block;
      padding: 0px 4px;
    }
    display: flex;
  }
  span {
    font-size: 42px;
    background: url(../../assets/images/numbbg.png ) no-repeat center
      center/contain;  
    width: 36px;
    letter-spacing: 0;
    color: #f04b38;
    height: 60px;
    margin: 0 2px;
    font-weight: 600;
    display: flex;
    justify-content: center;
  }
}
</style>
<script>
export default {
  name: "countDown",
  data() {
    return {
      hour: "", //时
      minute: "", //分
      second: "", //秒
      flag: false,
      endtime: "",
      nowtime: "",
      sendTime: "",
      starttime: " ",
      countdown: "",
      startFlag: false,
      Ccountdowndata:null,
    };
  },
  mounted() {
    this.countDown(this.countdowndata); 
  },
  props: ["countdowndata"],
  methods: {
    timeDown(response) {
      console.log('fffffffffffffffff',response)
      let that = this;
      that.starttime = response.activity_round.guess_time_start; // 开始时间
      that.endtime = response.activity_round.guess_time_end; // 结束时间
      let starttime = new Date(this.starttime).getTime();
      let endtime = new Date(this.endtime).getTime();
      let nowTime = new Date().getTime();
      that.countdown = response.activity_round.countdown;
      let dTime = 0;

      if (nowTime > endtime) {
        this.hour = "00"; //时
        this.minute = "00"; //分
        this.second = "00"; //秒
      } else {
        let time = setInterval(() => {
          if (this.flag == true) {
            clearInterval(time);
          }

          let nowTime = new Date();
          if (nowTime > starttime && nowTime < endtime) {
            dTime = parseInt((endtime - nowTime) / 1000); 
          } else if (nowTime < starttime) {
            dTime = that.countdown;
          } else {
            this.hour = "00"; //时
            this.minute = "00"; //分
            this.second = "00"; //秒
            this.flag = true;
          }
          this.hour = (this.formate(parseInt(dTime / (60 * 60))) + "").split(""); //时
          this.minute =(this.formate(parseInt((dTime / 60) % 60)) + "").split("") ; //分
          this.second = (this.formate(parseInt(dTime % 60)) + "").split(""); //秒
        
        }, 500);
      }
    },
    countDown(data) {  
        this.timeDown(data); 
    },
    formate(time) {
      if (time >= 10) {
        return time;
      } else {
        return `0${time}`;
      }
    },
  },
  // watch:{
  //   countdowndata(val){ 
      
  //   }
  // }
};
</script>
